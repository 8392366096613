import { Role, roleCollectionKey } from "@/entities/role";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import axios from "axios";
import store from "@/store";

function convertToRole(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Role {
  return {
    ref,
    data: {
      ref: data.ref,
      type: data.type,
      loginId: data.loginId,
      code: data.code
    }
  };
}

export async function checkAuthSessionState(): Promise<{
  uid: string;
  expiresAt: number;
  notFound?: boolean;
}> {
  try {
    const res = await axios.get("/check_auth_session", {
      withCredentials: true
    });
    return {
      uid: res.data.uid ?? "",
      expiresAt: res.data.expiresAt ?? 0,
      notFound: res.data.not_found ?? false
    };
  } catch (e) {
    console.error("error when fetching auth state", e);
    return { uid: "", expiresAt: 0 };
  }
}

export async function createAuthSession(idToken: string) {
  if (!idToken) return { ok: false };
  const res = await axios.post("/create_auth_session", { idToken });
  if (res.data?.status !== "success") {
    throw new Error("failed to create session");
  }

  return { ok: true };
}

export async function attemptCreateAuthSession(idToken: string) {
  try {
    await createAuthSession(idToken);
  } catch (e) {
    console.error("Failed to create auth session", e);
  }
}

export async function destroyAuthSession() {
  const res = await axios.post(
    "/destroy_auth_session",
    {},
    { withCredentials: true }
  );
  if (res.data?.status !== "success") {
    throw new Error("failed to remove session");
  }
}

export async function createCustomToken(
  uid: string
): Promise<{ token: string }> {
  if (!uid) return { token: "" };
  const call = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("create_custom_token");
  const res = await call({ uid });
  return { token: res.data.customToken ?? "" };
}

export async function signInWithIdAndPassword(
  loginId: string,
  password: string,
  schoolId: string,
  token?: string
): Promise<firebase.User> {
  const studentLogin = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("student_login");

  const loginRes = await studentLogin({
    loginId,
    password,
    schoolId,
    token
  });
  if (loginRes.data.error) {
    throw new Error(loginRes.data.error);
  }

  const userCredential = await firebase
    .auth()
    .signInWithCustomToken(loginRes.data.token);

  if (!userCredential.user) {
    throw new Error("");
  }

  const idToken = await userCredential.user.getIdToken();
  if (!idToken) {
    throw new Error("token id not found");
  }
  await attemptCreateAuthSession(idToken);

  return userCredential.user;
}

export async function signInWithCode(
  code: string,
  token?: string
): Promise<firebase.User> {
  const studentLogin = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("student_login");

  const loginRes = await studentLogin({ code, token });
  if (loginRes.data.error) {
    throw new Error(loginRes.data.error);
  }

  const userCredential = await firebase
    .auth()
    .signInWithCustomToken(loginRes.data.token);

  if (!userCredential.user) {
    throw new Error("");
  }

  const idToken = await userCredential.user.getIdToken();
  if (!idToken) {
    throw new Error("token id not found");
  }
  await attemptCreateAuthSession(idToken);

  return userCredential.user;
}

export async function signOut() {
  try {
    store.commit("SET_IS_SIGNING_OUT", true);
    try {
      await destroyAuthSession();
    } catch (e) {
      console.error("セッション破棄エラー", e);
    }
    await firebase.auth().signOut();
  } finally {
    store.commit("SET_IS_SIGNING_OUT", false);
  }
}

export async function getRole(id: string): Promise<Role | null> {
  const db = firebase.firestore();

  try {
    const snapshot = await db
      .collection(roleCollectionKey)
      .doc(id)
      .get();
    const data = snapshot.data();
    if (!snapshot.exists || !data) {
      return null;
    }

    return convertToRole(data, snapshot.ref);
  } catch (e) {
    console.error(e);
    return null;
  }
}
