<template>
  <m-page-template>
    <router-view />
  </m-page-template>
  <MLoadingOverlay v-if="loading" />
</template>

<script lang="ts">
import MPageTemplate from "@/components/MPageTemplate.vue";
import MLoadingOverlay from "@/components/MLoadingOverlay.vue";
import liff from "@line/liff";
import { Options, Vue } from "vue-class-component";
import store from "./store";

@Options({
  components: {
    MPageTemplate,
    MLoadingOverlay
  }
})
export default class App extends Vue {
  get loading(): boolean {
    return store.state.loading;
  }

  created() {
    let liffId = process.env.VUE_APP_LIFF_ID; // デフォルトは公式LINE AのLIFF_ID
    const params = new URLSearchParams(window.location.search);
    const liffIdFromQueryParam = params.get("liff_id");
    if (liffIdFromQueryParam) {
      liffId = liffIdFromQueryParam;
    }

    liff
      .init({ liffId })
      .then(() => {
        store.commit("SET_INIT_LIFF", true);
        if (liff.isInClient() && !liff.isLoggedIn()) {
          liff.login();
        }
        store.commit("SET_IS_LIFF_INITIALIZATION_COMPLETED", true);
      })
      .catch(e => {
        console.error("Error when initializing liff", e);
        alert(
          "LINEへの接続に失敗しました。" +
            "\n" +
            "LINE上での利用でなければこのままお使いいただけます。"
        );
        store.commit("SET_INIT_LIFF", false);
        store.commit("SET_LIFF_INIT_FAILED", true);
        store.commit("SET_IS_LIFF_INITIALIZATION_COMPLETED", true);
      });
  }
}
</script>

<style lang="scss">
body {
  @apply bg-primary-100;
  font-family: "Noto Sans JP", sans-serif !important;
}
</style>
